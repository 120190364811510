.containerBlock {
    padding: 30px;
    flex: 1 1;
}

@media (min-width:769px) {
    .tileList {
        display: flex;
        margin: 0;
    }

    .tileList>li:not(last-child) {
        border-right: 1px solid #e0e0e0;
    }

}

@media (min-width: 768px) {
    .headingGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
    }
}

@media (max-width:768px) {
    .tileList>li:not(:first-child) {
        border-top: 1px solid #e0e0e0;
    }

    .groupRight {
        justify-content: flex-end;
    }
}

.sronly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.headingResource {
    margin: 0;
    font-size: 1.5rem !important
}

.groupRight {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.hw-next-btn,
.hw-prev-btn {
    padding: 0;
    background-color: #fff !important;
    min-width: 62px;
    height: 25px;
}

.hw-prev-btn {
    margin-right: 10px;
}

.disabled,
.disabled:hover {
    background-color: #f2f2f2;
    border: 2px solid #ddd;
    pointer-events: none;
}

.disabled>svg {
    fill: #ddd;
}

.disabled {
    fill: #ddd;
}

.f-img-container>img {
    width: 100%;
    height: 92px;
    border-radius: 8px;
}

.container-block {
    padding: 40px;
    flex: 1;
}

.feature-list>li:not(last-child) {
    border-right: 1px solid #e0e0e0;
}

.feature-list {
    margin: 0;
}

.resourceCard {
    border-radius: 8px !important;
}

.resourceContainer {
    border-top: 1px solid rgb(224, 224, 224);
    padding: 20px 0;
    margin-top: -20px;
    background-color: rgb(251, 252, 254);
}

.resourceContainerGalileo {
    border-top: 1px solid rgb(224, 224, 224);
    padding: 30px 0;
    /* margin-top: 60px; */
    background-color: rgb(251, 252, 254);
}

.pagination {
    margin: 0 10px;
}

.styledContainer {
    padding: 0 27px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1180px;
    width: 100%;
}

.disabled {
    color: currentColor;
    display: inline-block;
    pointer-events: none;
    text-decoration: none;
}