.section {
  padding: 30px 0;
  margin-bottom: 30px;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1180px;
  padding: 0 27px;
}

@media (min-width:991px) {
  .container {
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 991px) {
  .leftCol,
  .middleCol {
    padding-bottom: 20px;
  }
}

.leftCol>img {
  width: 197px;
  height: 262px;
}

.middleCol>h3 {
  margin-bottom: 5px;
}

.ulist {
  list-style: disc;
  font-weight: 600;
  margin-top: 5px;
}

.ulist>li:not(:first-child) {
  padding-top: 8px;
}
@media (max-width: 510px) {
  .setUpBtn{
    max-height: none;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      font-family: 'UHC2020 Sans';
  }
}