.premium-payment-head {
    color: rgb(0, 38, 119);
    margin-bottom: 0px;
    font-size: 1.5rem;
}

@media (max-width: 510px) {
    .bottomBorder{
        margin-top: 20px;
        border: 1px solid #CCCCCC;
    }
    .premium-payment-head {
        font-weight: 600;
        font-size: 25.63px;
        color: #002677;
        line-height: 32px;
    }

    .premium-payments-header {
        font-family: "UHC2020 Sans";
        font-weight: 700 !important;
        font-size: 20.25px !important;
        line-height: 24px;
    }

    .premium-pay-now-btn {
        width: 100px;
        height: 42px;
        border-radius: 24px;
        padding: 8px 17px;
        font-size: 16px;
        line-height: 20px;
        font-family: "UHC2020 Sans";
    }

    .auto-pay-title {
        color: #002677;
        font-family: "UHC2020 Sans";
        font-weight: 700;
        font-size: 20.25px;
        line-height: 24px;
    }

    .set-auto-btn-mobile {
        border: 2px solid#002677 !important;
        width: 151px;
        height: 42px !important;
        border-radius: 24px !important;
        padding: 8px 16px !important;
        font-size: 16px !important;
        line-height: 20px !important;
        font-family: "UHC2020 Sans" !important;
        font-weight: 600 !important;
    }
    .cancel-text-mobile {
        font-size: 16px !important;
        line-height: 20px !important;
        font-family: "UHC2020 Sans" !important;
        font-weight: 600 !important;
    }

}

@media (max-width: 767px) {
    .AutoPayList {
        display: flex;
        align-items: center;
        position: relative;
    }

    .img-container {
        position: relative;
        top: -40px;
        padding-left: 15px;
    }

    .img-box {
        width: 76px;
        height: 76px;
        margin: unset;
    }
}

@media (max-width: 425px) {
    .premium-payments-header {
        padding-top: 0px !important
    }
}