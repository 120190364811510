section {
  padding: 20px 0;
}

@media (min-width: 991px) {
  .section-div {
    margin: 0 auto;
    width: 100%;
    max-width: 1180px;
    padding: 0 27px;
  }
}

.section-div {
  margin: 0 auto;
  padding: 0 27px;
}

.banner-section>section {
  background: rgb(229, 248, 250);
  box-shadow: 7px 2px 3px 2px rgba(0, 0, 0, 0.1);
}

.superuser-banner>section {
  background: rgb(255, 224, 224);
}

.covid-banner-section>section {
  background: #fff;
  box-shadow: 0px 2px 11px 1px rgba(0, 0, 0, 0.1);
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

@media (max-width: 991px) {
  .alignItemsMd {
    align-items: flex-start;
  }
}

.banner-font {
  font-family: 'UHC Sans';
  font-size: 16px;
  font-weight: 600;
  color: rgb(0, 38, 119);
  margin: 0;
}

.covid-banner {
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.covid-banner-style {
  display: flex;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex: 0 0 auto;
  place-self: auto;
  -webkit-box-pack: start;
}

.outlined {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  max-width: 100%;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  padding: 0px 15px;
  cursor: pointer;
  border-radius: 5px !important;
  vertical-align: middle;
  background: rgb(255, 255, 255) !important;
  border: 1px solid #1662b8 !important;
  color: #1662b8 !important;
}

.outlined:hover {
  background: #1662b8 !important;
  border: 1px solid #1662b8 !important;
  color: #fff !important;
}

.typography-dental {
  font-family: 'UHC Sans';
}

.x-button {
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: none;
  color: #000000;
  font-weight: 700;
  min-width: 50px;
}

.x-button:hover {
  background: rgb(255, 255, 255);
  color: #000000;
  border: none;
  font-weight: 700;
}

footer {
  padding: 0;
  border-top: 1px solid #e0e0e0;
  background: #f5f5f5;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.3rem 15px;
  padding: 0px 27px;
}

@media (min-width: 576px) {
  .grid {
    column-gap: 27px;
  }
}

.grid {
  margin-left: -27px;
  margin-right: -27px;
}

.col-span-3 {
  grid-column: span 3 / auto;
}

.col-span-6 {
  grid-column: span 6 / auto;
}

.col-span-12 {
  grid-column: span 12 / auto;
}

@media (max-width: 768px) {

  .col-span-3,
  .col-span-6 {
    grid-column: span 12 / auto;
  }

  .x-button {
    padding: 0;
  }

  .outlined {
    margin-top: 20px;
  }
}

@media (max-width: 989px) and (min-width: 768px) {
  .col-span-3 {
    grid-column: span 6 / auto;
  }
}

.footer-heading {
  font-size: 16px;
  font-weight: 600;
  font-family: 'UHC Sans';
  margin: 20px 0 10px 0;
}

.footer-list li {
  display: block;
  padding-top: 0.5rem;
}

.footer-list li>a {
  font-size: 15px;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin-top: 0;
}

.footer-list.terms>li {
  display: inline-block;
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 0;
}

.footer-item {
  border-left: 1px solid rgb(179, 186, 188);
}

.footer-item:first-child {
  border-left: 0px none;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.footer-list.terms {
  margin: 1.3rem 0px;
  display: block;
}

.border {
  border-top: 1px solid #ccc;
}