.main {
    margin: 0 auto;
    width: 100%;
    max-width: 1180px;
    padding: 20px 27px 0 27px;
    margin-bottom: 40px;
}

.buttonContainer {
    display: flex;
    justify-content: right;
    flex: 1 0;
}

.mobileAppTileLink {
    font-size: 16px;
    margin-left: 35px;
}

.styledHeading {
    margin-bottom: 0;
}


.primaryCareCol>h3,
.urgentCareCol>h3 {
    margin-left: -10px;
}

.image>img {
    width: 197px;
}

.imagePhone>img {
    width: 162px;
}

.discList {
    list-style: disc;
    line-height: 2;
    margin-top: 0;
    margin-left: 17px;
}

.discList>li {
    font-weight: 600;
    font-size: 16px;
}

@media (min-width: 991px) {
    .container {
        display: flex;
        justify-content: space-between;
    }

    .leftCol,
    .rightCol {
        display: flex;
        flex-direction: column;
        flex: 50%;
    }

    .urgentCare,
    .care {
        display: flex;
    }

    .urgentCareCol {
        padding-left: 20px;
    }

    .rightCol {
        border-left: 1px solid #ccc;
        padding-left: 50px;
        margin-left: 20px;
    }
    .getCareBtn {
        margin-top: -20px;
    }

    .getStartedBtn {
        margin-top: 20px;
    }

    .parContainer {
        display: flex;
        flex: 1 0;
    }
}

@media (max-width: 990px) {

    .image,
    .imagePhone {
        display: none;
    }

    .rightCol {
        padding: 20px 0;
        margin-top: 30px;
        border-top: 1px solid #ccc;

    }
}