.btn-styled>.link-text {
    text-align: center;
}

.custom-width {
    width: 65%;
}

.shop-plan-button {
    text-transform: none !important;
}

.shop-plan-button>svg {
    fill: currentColor !important;
    color: currentColor !important;
}