.wrapInfo {
  background: rgb(229, 248, 251);
  border-bottom: 2px solid rgb(0, 38, 119);
  padding: 5px 0;
}

.wrapAlert {
  background-color: #fef9ea;
  border-bottom: 2px solid #ffb500;
  padding: 5px 0;

}

.wrapSuccess {
  background: #eff6ef;
  border-bottom: 2px solid #007000;
  padding: 5px 0;
}

.wrapError {
  background-color: #fcf0f0;
  border-bottom: 2px solid #c40000;
  padding: 5px 0;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1180px;
  padding: 0 27px;
}

.mobileFlex {
  display: flex;
  align-items: center;
}

.mobileOnly {
  font-size: 20px;
  font-family: 'UHC Sans Bold', Arial, sans-serif;
  font-weight: 600;
  margin-left: 20px;
}

.mobileOnlyBanner {
  display: flex;
  align-items: center;
}

.desktopOnly {
  display: none;
}

.desktopOnlyBanner {
  display: none;
  }

.bannerBody {
  margin-top: 0;
  color: #5a5a5a;
}

/* .button {
  min-width: 240px;
} */

@media (min-width: 768px) {
  .mobileFlex {
    display: block;
  }

  .mobileOnly {
    display: none;
  }

  .mobileOnlyBanner {
    display: none;
  }

  .desktopOnlyBanner {
    display: flex;
  }

  .desktopOnly {
    display: block;
    margin: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flexWrap {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 15px;
  }

  .bannerBody {
    margin: 5px 0 0 0;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

[id="markup-margin"] h3,
[id="markup-margin"] p {
  margin: 10px 0 !important;
}
