.alertContainer {
    margin: 30px 0;
  }
  
  .contentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: #E5F8FB;
    border: 1px solid #CCF2F7;
    padding: 20px;
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .pStyled {
    margin-bottom: 0;
  }