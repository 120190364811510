.overlay {
    width: 2000px;
    height: 4000px;
    display: flex;
    flex-shrink: 0 !important;
    background: rgba(0, 0, 0, 0.50);
  }
  .modalbody{
     padding: 0 !important;
  }
.MessageBox{
    display: flex;
    justify-content: center;
    width:600px;
height: 470px;
padding: 0px 40px 24px 40px;
position: absolute;
flex-direction: column;
align-items: center;
gap: 16px;
background: var(--uhc-primary-white, #FFF);
}
.imageBox{
text-align: center;
background: var(--uhc-secondary-pacific, #00BED5) !important;
}
.textBox{
    color: var(--uhc-primary-primary-blue, #002677) !important;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
padding-top: 16px;

/* UHC/Headings/H2 */
font-family: UHC Serif Headline;
font-size: 35px;
font-style: normal;
font-weight: 600;
line-height: 48px; /* 148.011% */
}
.subText{
    color: var(--data-viz-dark-gray, #4B4D4F) !important;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* UHC/P/LG */
font-family: UHC2020 Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
}
.button{
    color: var(--uhc-primary-white, #FFF);
font-feature-settings: 'clig' off, 'liga' off;

/* UHC/Button/Primary */
font-family: UHC2020 Sans;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 125% */
    display: flex;
height: 48px;
padding: 8px;
align-items: flex-start;
border-radius: 34px;
border: 2px solid var(--uhc-primary-uhc-blue, #002677);
background: var(--uhc-primary-uhc-blue, #002677);
}
.closebutton{
    
position: absolute;
right: 15px;
top: 15px;
}
.MessageBox1{
    display: flex;
padding: 0px 40px 24px 40px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
align-self: stretch;
}
@media only screen and (min-width : 1400px) {
    .modal700w{
        width: 700px !important;
        max-width: none !important;
      }
}
@media only screen and (max-width: 1439px) and (min-width : 768px ) {
    .modal700w{
        width: 700px !important;
        max-width: none !important;
      }
    
}
@media only screen and (max-width: 767px) and (min-width : 540px ) {
    .modal700w{
        max-width: none !important;
      }
}
@media only screen and (max-width: 539px) and (min-width : 320px ) {
    .modal700w{
        max-width: none !important;
      }

}