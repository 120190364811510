.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media (max-width: 991px) {
 .icondesktop {
   display: none;
 }
}

@media (min-width: 992px) {
  .iconmobile {
    display: none;
  }
}

.referralCard {
    background-color: #E5F8FB;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.cardContainerLeft,
.cardContainer {
    border: 1px solid #E0E0E0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    flex: 80%;
    padding: 10px;
}

.referralCard>.cardContainerLeft {
    border: none;
}


.icon {
    margin-right: 10px;
}

.flexContainer {

    display: flex;
    align-items: center;
    justify-content: center;
}

.referralBtn {
    cursor: pointer;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    background-color: #002677;
    color: #fff !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    flex: 20%;
}

.referralBtn:hover>span {
    text-decoration: none !important;
}

.expandIcon {
    color: #fff;
}

.referralBtn>span {
    display: flex;
    align-items: center;
}

.centerReferral>span {
    width: 80%;
}

.referralNr {
    width: 30px;
    height: 30px;
    line-height: 32px;
    display: block;
    text-align: center;
    border-radius: 50%;
    background-color: #196ecf !important;
    font-weight: 700;
    color: #fff !important;
    margin-left: 15px;
}

@media (min-width: 991px) {
    .mobile {
        display: none
    }

    .card,
    .referralCard {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }

    .leftSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .providerWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .itemOne,
    .referralItemOne {
        display: flex;
        align-items: center;
    }

    .changeProvLink {
        padding-left: 50px;
    }

    .referralItemOne {
        flex: 1 0;
    }
}

@media (max-width: 768px) {
    .desktop {
        display: none;
    }

    .itemOne,
    .referralItemOne {
        display: flex;
        align-items: flex-start;
    }

    .changeProvLink {
        display: flex;
        justify-content: center;
    }

    .referralCard,
    .cardContainerLeft {
        border-bottom-left-radius: 0;
        border-top-right-radius: 8px;
        margin-top: 10px;
    }

    .card {
        margin: 20px 0;
    }

    .referralBtn {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0px;
        padding: 10px
    }
}