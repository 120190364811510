/* Member Modal styles */

input[type='radio']+label {
  color: #196ecf;
  position: relative;
  vertical-align: middle;
}

input[type='radio']:checked,
input[type='radio']:checked+label:before {
  background: #196ecf;
}

input[type='radio']+label:before {
  background: #fff;
  border: 3px solid #196ecf;
  border-radius: 0.5em;
  content: '';
  display: inline-block;
  font-size: 2em;
  height: 1em;
  left: -webkit-calc(-1.2em - 4px);
  left: calc(-1.2em - 4px);
  margin-right: calc(-1.2em + 4px);
  position: relative;
  vertical-align: middle;
  width: 1em;
}

.account-selector label {
  font-weight: 600;
  text-transform: uppercase;
}

.account-selector label .member .member-name {
  font-size: 1.4em;
}

.account-selector label .member .member-info {
  font-weight: 400;
  line-height: 1.6em;
}

.radio {
  cursor: pointer;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

li input {
  margin-left: 10px;
}

.account-selector {
  padding: 20px 0;
  margin: 5px 0;
  border-bottom: 1px solid #ccc !important;
}

.account-selector:focus {
  outline: 5px solid #a2cb4c !important;
}

fieldset {
  border: 0 !important;
}

.styled-content {
  border-top: 0;
  margin: 0 0 30px !important;
  padding: 0 30px !important;
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  font-size: 1.5em;
  height: 1em;
  margin-right: 13px;
  overflow: hidden;
  vertical-align: middle;
  visibility: visible;
  border-radius: 50%;
  width: 1em;
  opacity: 0;
}

.account-selector label {
  font-weight: 600;
  text-transform: uppercase;
}

input[type='radio']+label {
  color: #196ecf;
  position: relative;
  vertical-align: middle;
}

.account-selector label .member {
  display: inline-block;
  vertical-align: middle;
}

.hide-outlines *,
.hide-outlines :focus {
  outline: 0 !important;
}

.styled-button:hover {
  text-decoration: none !important;
}

.fade-in {
  -webkit-animation: fade-in 1s;
  animation: fade-in 1s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.link-button {
  background-color: transparent !important;
  border: none !important;
  color: #196ecf !important;
  padding: 0 !important;
  font-weight: 600 !important;
  font-family: 'UHC Sans', Arial, sans-serif !important;
  font-size: 15px !important;
}

.link-button:hover {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding-bottom: 0 !important;
}

.link-button>svg {
  color: #196ecf !important;
}

.uhc-tempo-link {
  max-height: 100% !important;
}