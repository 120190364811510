.alertContainer {
    background-color: #E5F8FB;
    border: 1px solid #CCF2F7;
    border-radius: 4px;
    padding: 10px 20px;
    margin: 20px 0;
}

@media(min-width: 991px) {
    .alertContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media(max-width: 768px) {
    .alertContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .btnGroup {
        flex: 1 0;
        justify-content: flex-end;
    }

    .contentWrapper {
        flex: 1 0;
    }
}

.contentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentWrapper>p {
    margin-bottom: 0;
}

.styledHeading {
    color: rgb(0, 38, 119);
    margin-right: 20px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px !important;
}

.Paragraph {
    display: block;
    margin-top: 0px;
    margin-bottom: 20px !important;
}


.icon {
    margin-right: 10px;
}

.btnGroup {
    display: flex;
    align-items: center;
}

.manageBtn {
    display: flex !important;
    align-items: center;
}

.manageBtn>svg {
    color: #002677;
    margin-left: 4px;
}

.choosePcpBtn {
    display: flex;
    align-items: center;

}

.choosePcpBtn>svg {
    color: #fff;
    margin-left: 4px;
}

.headingStyled {
    color: #333;
}

.list {
    display: flex;
    justify-content: flex-start;
}

.dt {
    text-align: left;
    min-width: 100px;
}

.button {
    display: flex;
    justify-content: flex-start;
}

/* Notification variation Module */
.referralCard {
    background-color: #E5F8FB;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.cardContainerLeft,
.cardContainer {
    border: 1px solid #E0E0E0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    flex: 80%;
    padding: 10px;
}

.referralCard>.cardContainerLeft {
    border: none;
}

.cardContainerRight {
    padding: 0 10px;
    background-color: #002677;
    color: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    flex: 15%;
}

.cardContainerRight {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 10px;
}

.flexContainer {

    display: flex;
    align-items: center;
    justify-content: center;
}

.referralBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.referralBtn>span>svg {
    color: #fff;
}

.referralBtn>span {
    display: flex;
}

.referralNr {
    width: 30px;
    height: 30px;
    line-height: 32px;
    display: block;
    text-align: center;
    border-radius: 50%;
    background-color: #196ecf;
    font-weight: 700;
    color: #fff;
    margin-left: 15px;
}

@media (min-width: 991px) {
    .mobile {
        display: none
    }

    .card,
    .referralCard {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }

    .leftSection {
        display: flex;
        align-items: center;
    }

    .providerWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .itemOne,
    .referralItemOne {
        display: flex;
        align-items: center;
    }

    .changeProvLink {
        padding-left: 50px;
    }

    .referralItemOne {
        flex: 1 0;
    }
}

@media (max-width: 768px) {
    .desktop {
        display: none;
    }

    .itemOne,
    .referralItemOne {
        display: flex;
        align-items: flex-start;
    }

    .changeProvLink {
        display: flex;
        justify-content: center;
    }

    .cardContainerRight {
        padding: 10px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
        display: flex;
        justify-content: center;
    }

    .referralCard,
    .cardContainerLeft {
        border-bottom-left-radius: 0;
        border-top-right-radius: 8px;
        margin-top: 10px;
    }

    .card {
        margin: 20px 0;
    }
}

@media(max-width: 500px) {
    .pcpTitle {
        font-size: 1.38rem !important;
        text-align: center !important;
    }
}