.linkModal {
    display: flex;
    align-items: center;
}

.linkModal>svg {
    color: #196ecf;
    width: 18px;
    height: 18px;
    margin-left: 5px;
}