.container {
  padding: 0 27px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1180px;
  width: 100%;
}

.icon {
  display: none;
  width: 58px;
}

.logoWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  max-height: 40px;
}

.type {
  margin-right: 10px;
  width: 110px;
}

p.header {
  font-size: 24px;
  /* margin-bottom: 0; */
  line-height: 28.8px;
}

.content {
  margin-bottom: 30px;
}

.phoneContainer {
  position: relative;
  line-height: 0;
}

.phone {
  margin: 0 auto;
  display: block;
  height: auto;
  width: 225px;
}

@media (min-width: 768px) {
  .icon {
    display: block;
  }

  .wrap {
    display: flex;
    margin-top: 40px;
  }

  .phone {
    width: 550px;
  }

  .content {
    order: 2;
    flex: 1;
  }

  .phoneContainer {
    order: 1;
    flex: 1;
  }

  .logoWrap {
    justify-content: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .body {
    max-width: 410px;
    margin-bottom: 15px;
  }
}