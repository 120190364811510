@media (max-width: 991px) {
    .desktop {
        display: none !important;
    }
}

@media (min-width: 991px) {
    .mobile {
        display: none !important;
    }
}

/* Member Modal styles */

input[type='radio']+label {
    color: #196ecf;
    position: relative;
    vertical-align: middle;
}

input[type='radio']:checked,
input[type='radio']:checked+label:before {
    background: #196ecf;
}

input[type='radio']+label:before {
    background: #fff;
    border: 3px solid #196ecf;
    border-radius: 0.5em;
    content: '';
    display: inline-block;
    font-size: 2em;
    height: 1em;
    left: -webkit-calc(-1.2em - 4px);
    left: calc(-1.2em - 4px);
    margin-right: calc(-1.2em + 4px);
    position: relative;
    vertical-align: middle;
    width: 1em;
}

.account-selector label {
    font-weight: 600;
    text-transform: uppercase;
}

.account-selector label .member .member-name {
    font-size: 1.4em;
}

.account-selector label .member .member-info {
    font-weight: 400;
    line-height: 1.6em;
}

.radio {
    cursor: pointer;
}

ol,
ul {
    list-style: none;
    padding-left: 0;
}

li input {
    margin-left: 10px;
}

.account-selector {
    padding: 20px 0;
    margin: 5px 0;
    border-bottom: 1px solid #ccc !important;
}

.account-selector:focus-within {
    outline: 5px solid #a2cb4c !important;
}

fieldset {
    border: 0 !important;
}

.styled-content {
    border-top: 0;
    margin: 0 0 30px !important;
    padding: 0 30px !important;
}

input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    font-size: 1.5em;
    height: 1em;
    margin-right: 13px;
    overflow: hidden;
    vertical-align: middle;
    visibility: visible;
    border-radius: 50%;
    width: 1em;
    opacity: 0;
}

.account-selector label {
    font-weight: 600;
    text-transform: uppercase;
}

input[type='radio']+label {
    color: #196ecf;
    position: relative;
    vertical-align: middle;
}

.account-selector label .member {
    display: inline-block;
    vertical-align: middle;
}

.hide-outlines *,
.hide-outlines :focus {
    outline: 0 !important;
}

.styled-button:hover {
    text-decoration: none !important;
}

.fade-in {
    -webkit-animation: fade-in 1s;
    animation: fade-in 1s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.link-button {
    background-color: transparent;
    border: none;
    color: #196ecf;
    padding: 0;
    font-weight: 600;
    font-family: 'UHC Sans', Arial, sans-serif;
    font-size: 15px;
}

.link-button:hover {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    padding-bottom: 0;
}

.link-button>svg {
    color: #196ecf;
}

.tds-button {
    /* max-height: 100%; */
    /* height: 100%; */
    text-transform: none !important;
    font-size: none !important;
}

button#see-a-doctor {
    max-height: 42px !important;
}

.active-plan-tile {
    height: 100%;
    text-align: center;
    display: flex;
    flex: 1 0;
    margin: 0;
    padding: 0 20px;
    justify-content: center;
}

.plan-tile-border {
    border-left: 1px solid #e0e0e0;
}

.btn-active-styled {
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.styled-h2 {
    color: rgb(0, 38, 119);
    margin: -15px 0 -5px 0;
}

.padded-grid {
    padding: 30px 40px 30px 40px;
}

.link-text {
    text-align: left;
}

h3.flex-heading-list {
    color: #fff;
    /* font-size: 1rem; */
}

h3.flex-heading {
    padding: 0 10px;
    min-width: 120px;
    line-height: 2;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 16px;

}


.tempo-v1 .tds-header__h2,
.tempo-v1 .tds-header__h2--light {
    font-size: 1.5rem;
    line-height: 1.3;
}

.tempo-v1>h2 {
    margin-top: 20px;
    margin-bottom: 20px !important;
}

.active-phone-img>img {
    height: 332px !important;
    max-width: 205px !important;
}

h3>svg {
    margin-right: 5px;
}

.styledContainer {
    padding: 0 27px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1180px;
    width: 100%;
}

.card {
    border-radius: 4px;
    border: 1px solid #E0E0E0;
}

.cardContainer {
    padding: 20px;
}

.providerWrapper1 {
    justify-content: flex-end;
    height: 100%;
    text-align: center;
    display: flex;
    flex: 1 0;
    margin: 0;
    padding: 0 20px;

}

.providerWrapperMobile {
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .AutoPayList {
        display: flex;
        align-items: center;
        margin-top: -20px;
        margin: 10px 0px;
    }

    .pay-now-list {
        display: flex;
    }

    .pay-now-list>div {
        flex: 1;
    }

    .pay-now-col {
        flex: 1;
        padding-right: 20px;
    }

    .styled-flex-wrap {
        display: flex;
        border-top: 0;
        padding-top: 0;
    }

    .virtual-care-col {
        flex: 1;
        padding: 15px;
        margin-bottom: 10px;
    }

    .auto-pay-col {
        flex: 1;
        padding: 15px;
        margin-bottom: 10px;
        border-left: 1px solid rgb(221, 221, 221);
    }

    .auto-pay-img {
        margin-top: -60px;
        margin-left: 20px;

    }

    .virtual-care-popup {
        top: -20px;
    }
}
.provider-card,
.pcp-card {
    margin-top: 32px;
    border-radius: 6px;
}

.g-provider-card {
    border-radius: 6px;
}

.uhc-tempo-card__content {
    padding: 10px !important;
}

.provider-card>.uhc-tempo-card__content,
.provider-card>.tds-card__content {
    padding: 0px !important
}


.pcp-card {
    width: 100% !important;
}

.tds-card {
    border-radius: 8px;
}

.payments>section {
    border-top: 1px solid #e0e0e0 !important;
}

.payments-top {
    position: relative;
    top: -130px;
}

.payment-galileo {
    top: 0;
    margin-bottom: 20px;
}

.active-bottom {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .active-bottom {
        margin-bottom: 50px;
    }
}

h2.tds-header__h3 {
    font-family: "UHC Serif Semibold", Georgia Bold, serif;
    font-size: 1.5rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.2487;
    color: #002677;
    margin-top: 20px;
}

h3.tds-header__h4 {
    font-size: 1.4rem;
}

h3.payments-header {
    color: rgb(0, 38, 119);
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    /* border-top: 1px solid rgb(224, 224, 224); */
    padding-top: 10px;
}

p.provider-name {
    /* margin-top: .75rem; */
    margin-bottom: -10px;
}

p#g-provider-name {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .payments-header {
        margin-top: 0;
        border-top: 0;
        padding-top: 0;
    }

    .claims-heading {
        font-size: 1.5rem;
        line-height: 1.3;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.payment-notification {
    position: relative;
    opacity: 1;
    padding: 24px 20px;
    transition: opacity .25s ease-in-out;
}

.notification-warning {
    border: 1px solid rgb(255, 181, 0);
    border-radius: 4px;
    background-color: #fef9ea;
}


.tab-title {
    background-color: rgb(249, 249, 249);
}

.border {
    border-top: 1px solid #e0e0e0 !important;
}

.accumulator>div {
    margin: 0 !important;
}

.accumulator-position {
    position: unset !important;
}

.minus-line {
    background: rgb(111, 111, 111) !important;
    height: 2px;
    width: 14px;
}

.btn-expand,
.btn-expand:hover {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.btn-expand:focus {
    border-radius: 2px;
}

span.paragraph {
    color: #333;
    font-size: 16px;
    margin: 0;
}

.expandable-section {
    display: flex;
}

.expandable-section.not-met {
    /* display: flex; */
    align-items: center;
}

.expandable-section>button>svg {
    color: #333;
}

.expandable-section>svg,
.minus-line {
    margin-right: 10px;
}

.expandable-section>svg {
    color: rgb(0, 112, 0);
}

/* .deductible-svg>svg {
    color: #5a5a5a !important;
} */

.deductible-container>svg {
    color: rgb(0, 112, 0) !important;
    margin-right: 10px;
}

.deductible-container {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.deductible-check {
    border: 1px solid rgb(0, 112, 0);
    border-radius: 8px;
    padding: 15px;
}

.virtual-list>li>h3>svg {
    color: #002677 !important;
    fill: #002677 !important;
}

@media (min-width: 991px) {
    .card-footer {
        color: #fff;
        padding: 20px;
        background-color: #002677;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        flex: 15% 1;
        align-items: center;
    }
}

@media (max-width: 991px) {
    .card-footer {
        padding: 10px;
        background-color: #002677;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
}

.card-footer-galileo {
    background-color: #002677;
    padding: 20px 80px;
    margin-left: auto;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.g-provider-card>.tds-card__content {
    padding: 0;
}

.flex-items {
    display: flex;
    align-items: center;
    justify-content: center;
}

p.bold-p {
    font-weight: 700;
    margin: 0;
}

.referral-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 6px;
}

.referral-btn>span>svg {
    color: #fff;
    margin-top: 5px;
}

.referral-btn>svg {
    color: #fff;
    margin-top: 5px;
}

.referral-number {
    width: 30px;
    height: 30px;
    line-height: 32px;
    display: block;
    text-align: center;
    border-radius: 50%;
    background-color: #196ecf;
    font-weight: 700;
    color: #fff;
    /* color: #002677; */
    margin-left: 15px;
}

.replace-provider-link {
    display: flex;
    align-items: center;
}

.replace-provider-link>svg {
    color: #196ecf;
    width: 18px;
    height: 18px;
    margin-left: 4px;
}

.provider-name>span {
    color: #002677;
    font-weight: 700;
    font-size: 16px;
    font-family: 'UHC Sans Bold', Arial, sans-serif;
    margin: 0;
}

@media (min-width: 989px) {
    .pcp-heading-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pcp-card {
        margin-top: 0;
    }
}

@media (max-width: 989px) {
    .replace-provider-link {
        margin-bottom: 10px;
    }

    .tds-header__h2 {
        margin: 0;
    }

    .providerWrapper {
        align-items: center;
        justify-content: space-between;
    }

    .providerWrapperMobile {
        display: flex;
        align-items: flex-start;
        padding: 10px;
        margin: 5px;
    }
}

.no-margin-bottom {
    margin-bottom: 0;
}

.virtual-care-container {
    padding: 0 0 20px 0;
}

.claims-member-section {
    background-color: rgb(251, 252, 254);
    padding: 20px 0 110px 0;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
}

@media (max-width: 768px) {
    .prp-mobile {
        margin-top: 20px
    }

    .virtual-list {
        display: block !important;
        margin: 0;
    }

    .vv-button {
        margin-top: 20px;
    }

    .virtual-care-container {
        margin-top: 30px;
        padding-bottom: 0 !important;
    }

    .virtual-list-mobile {
        display: flex;
        justify-content: space-between;
    }

    .active-phone-img>img {
        max-width: 190px !important;
        height: 230px !important;
    }

    .virtual-care-popup {
        top: -10px;
    }
}

@media (max-width: 767px) {
    .virtual-care-container {
        margin-top: 30px;
    }
}

.no-claim-p {
    text-align: center;
    position: relative;
    top: -100px;
    font-style: italic !important;
    font-size: 12px !important;
}

.no-claim-card {
    width: 100%;
    height: 120px;
}

.member-name-wrap {
    display: block;
}

.meet-now-lnk>span>span>svg {
    color: #196ecf !important;
    width: 18px;
    height: 18px;
    margin: 0 0 -4px 4px;
}

.meet-now-lnk>span {
    display: flex;
    align-items: center;
}

.arrow-link {
    display: flex;
    align-items: center;
}

.arrow-link>svg {
    color: #196ecf;
    margin-left: 5px;
    width: 18px;
    height: 18px;
}

h2.member-typo-heading {
    display: inline-block;
    font-size: 16px;
    margin: 0;
    font-family: "UHC Sans Bold", Arial, sans-serif;
}

.member-info-wrapper {
    white-space: nowrap;
    text-align: right;
    margin: 10px 0 0 15px;
}

.member-popup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.uhc-tempo-button {
    text-transform: none !important;
}

.changeprovider-button {
    display: flex;
    align-items: center;
}

.changeprovider-button svg {
    margin-left: 10px;
}

.changeprovider-btn-mobile {
    margin-left: 40px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255) !important;
    /* top: -30px; */
    position: relative;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    display: none;
    width: 180px;
    background-color: #ffffff;
    color: #5a5a5a;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;

    border: 1px solid gray;
    font-size: 12px;
    /* font-weight: lighter; */
    font-family: 'UHC Sans', Arial, sans-serif;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: gray transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    display: block;
}

@media (max-width: 1023px) {
    .tooltip .tooltiptext {
        bottom: 120% !important;
        right: -32px !important;
        margin-right: 0 !important;
    }

    .providerWrapper {
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        gap: 100px;
    }
}

@media (min-width: 1024px) {
    .tooltip .tooltiptext {
        bottom: 150%;
        left: 50%;
        margin-left: -60px;
    }

    .providerWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        gap: 100px;
    }
}

#mng-pcp-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 990px) {
    #mng-pcp-btn {
        margin-left: 60px;
    }
}

#mng-pcp-btn>svg {
    color: #fff;
}

.edit-change-prov {
    display: flex;
    align-items: center;
}

.edit-change-prov>span>svg {
    color: #196ecf;
    margin-left: 5px;
}

.edit-change-prov>span {
    display: flex;
    align-items: center;
}

.blue-utility {
    color: #002677 !important;
}

#markup-margin>span>p {
    margin: 0
}

.tds-popup-tip__button>svg {
    vertical-align: unset;
}


/* div.styledContainer>div>div[class^=notification_card_]>div {
    flex: 100%;
} */
@media (max-width: 767px) {
    .AutoPayList {
        display: flex;
        align-items: center;
        position: relative;
    }
    .img-container {
        position: relative;
        top: -40px;
        padding-left: 15px;
    }

    .img-box {
        width: 100px;
        height: 100px;
        margin: unset;
    }
}
@media screen and (max-width: 610px) {
    .pay-now-btn-mb {
        flex-direction: column;
    }

    .pay-now-button {
        width: 20px;
        margin: 5px 0 0 34px;
    }
}

@media (max-width: 425px) {
    .pay-now-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .pay-now-list-items {
        flex: 50%;
    }

    .pay-now-list-para-items {
        flex: 100%
    }
}
